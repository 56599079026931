import consumer from "./consumer";

consumer.subscriptions.create("ServerinfoChannel", {
  connected() {
    this.perform("getData");
  },
  received(data) {
    // Don't continue if the chart isn't setup
    if (
      typeof chart === "undefined" ||
      typeof chart.series[0] === "undefined"
    ) {
      if (data["type"] == "serverData") {
        data = data["data"];
        for (var i = 0; i < data.length - 1; i++) {
          chart.addSeries(data[i], false);
        }
        chart.hideLoading();
        chart.addSeries(data[data.length-1]);
      }
      return;
    }

    if (data["type"] == "serverData") {
      return;
    }

    data = JSON.parse(data.body);

    // TODO: Find a way to get min, this will never update so min is always the same
    var xAxisMin = Math.min(
      Math.min(chart.xAxis[0].min, chart.xAxis[0].dataMin),
      chart.xAxis[0].userOptions.min
    );
    var updatedSize = false;
    
    var totalPlayers = 0;

    for (var i = 0; i < data["servers"].length; i++) {
      var hash = data["servers"][i];

      totalPlayers += parseInt(hash["players"]);

      if (!updateStatus(hash["name"], hash["status"])) continue;

      document.getElementById(hash["name"] + "-players").innerHTML =
        hash["players"];

      for (var j = 0; j < chart.series.length; j++) {
        if (chart.series[j].name == hash["name"]) {
          // To check if we have value for this time already
          var xAxisData = chart.series[j].xData;
          var includes = false;
          // To check if we should overwrite previous player number
          var yAxisValue =
            chart.series[j].processedYData[
              chart.series[j].processedYData.length - 1
            ];

          // Check if point already exists with this time
          if (!(includes = xAxisData.includes(data["time"]))) {
            if (!updatedSize) {
              chart.xAxis[0].update({
                min: data["xmin"],
                max: data["xmax"],
              });
              updatedSize = true;
            }

            // Add latest point
            chart.series[j].addPoint([data["time"], hash["players"]]);
          } else if (includes) {
            chart.series[j].data[chart.series[j].data.length - 1].update({
              y: hash["players"],
            });
            // console.log("edited point");
          }

          // Remove outdated points
          chart.series[j].data.forEach(function (point, index) {
            if (point.x < xAxisMin && point.x) {
              //console.log("Removed point from " + hash["name"] + ": " + point.x + " min(" + xAxisMin + ")");
              point.update(null);
              point.y = null;
              point.x = null;
            }
          });

          break;
        }
      }
    }

    document.getElementById("global-online").innerHTML = totalPlayers;
  },
});
